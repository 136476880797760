



$white:#fff;
$green: #3caf99;



$primaryone: #E94560;
$primarytwo: #21CCAC;

$darkone: #16213E;




$breakpoint-xxl: 1700px;
$breakpoint-xl: 1500px;
$breakpoint-lg: 1100px;
$breakpoint-md: 768px;
$breakpoint-sm: 600px;
$breakpoint-xs: 450px;

@mixin font-light {
    font-family: 'Figtree', sans-serif;
    font-weight: 300;
}

@mixin font-normal {
    font-family: 'Figtree', sans-serif;
    font-weight: 400;
}

@mixin font-medium {
    font-family: 'Figtree', sans-serif;
    font-weight: 500;
}

@mixin font-semi {
    font-family: 'Figtree', sans-serif;
    font-weight: 600;
}

@mixin font-bold {
    font-family: 'Figtree', sans-serif;
    font-weight: 700;
}

@mixin font-extra {
    font-family: 'Figtree', sans-serif;
    font-weight: 800;
}

@mixin font-black {
    font-family: 'Figtree', sans-serif;
    font-weight: 900;
}

@mixin link-animation {
    transition: all 0.3s cubic-bezier(0.8, 0.1, 0.1, 0.8);
}

@mixin button {
    transition: all 0.3s cubic-bezier(0.8, 0.1, 0.1, 0.8);
    background:$primaryone;
    border:none;
    @include font-medium;
    color:$white;
    font-size:1.2rem;
    border-radius:4px;
    padding:15px 30px;
    svg {
        fill:$white;
        display:inline-block;
        width:16px;
        margin-right:13px;
    }
    &:active {
        transform:translateY(4px);
    }
    
}

@keyframes anvil {  
    0% {
        transform: scale(1) translateY(0px);
        opacity: 0;
    }  
    1% {   
        transform: scale(0.96) translateY(10px);    
        opacity: 0; 
    }  
    100% {    
        transform: scale(1) translateY(0px);    
        opacity: 1;
    }
}

@keyframes overlay {  
    0% {
        opacity: 0;
    }  
    1% {  
        opacity: 0; 
    }  
    100% { 
        opacity: 1;
    }
}

@keyframes tilt-shaking {
    0% { transform: rotate(0deg) translateY(1px); }
    25% { transform: rotate(7deg) translateY(1px); }
    50% { transform: rotate(0eg) translateY(1px); }
    75% { transform: rotate(-7deg) translateY(1px); }
    100% { transform: rotate(0deg) translateY(1px); }
  }

  @keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}