* {
    box-sizing:border-box;
    cursor: none !important;
    &::selection {
        background:$primaryone;
        color:$white;
    }
}

body {
    background: $darkone;
}

h1, h2, h3, h4, h5, h6 {
    @include font-bold;
    line-height:1;
    &::selection {
        background:$primaryone;
        color:$white;
    }
}

p {
    @include font-normal;
    line-height:1.5;
    strong {
        @include font-semi;
    }
}

a {
    color:$primaryone;
    text-decoration:none;
    @include link-animation;
    &:hover {
        color:$primarytwo;
    }
}

.main-holder {
    display:flex;
    justify-content: space-between;
    height:100vh;
    @media screen and (max-width: $breakpoint-lg) {
        flex-direction: column;
    }
    .left {
        padding: 4rem;
        width:55%;
        @media screen and (max-width: $breakpoint-lg) {
            width: 100%;
        }
        @media screen and (max-width: $breakpoint-sm) {
            padding: 3rem;
        }
        .km-logo {
            width: 80px;
            height: auto;
        }
        .intro {
            padding-right:25%;
            @media screen and (max-width: $breakpoint-xxl) {
                padding-right: 0;
            }
            @media screen and (max-width: $breakpoint-xl) {
                padding-right: 0;
            }
            @media screen and (max-width: $breakpoint-lg) {
                padding-right:0;
            }
            @media screen and (max-width: $breakpoint-sm) {
                
            }

            h2 {
                font-size: 4rem;
                color: $white;
                margin-bottom: 2rem;
                margin-top:3rem;
                
                @media screen and (max-width: $breakpoint-sm) {
                    font-size: 2.4rem;
                    margin-bottom: 1rem;
                }
            }

            p {
                font-size: 1.7rem;
                color: $white;

                @media screen and (max-width: $breakpoint-sm) {
                    font-size: 1.2rem;
                }
            }
        }
    }
    .right {
        background:$primaryone;
        padding: 4rem;
        position:relative;
        @media screen and (max-width: $breakpoint-lg) {
            width:100%;
        }
        .social {
            display: flex;
            align-self: flex-start;
            justify-content: right;
            width:100%;
            @media screen and (max-width: $breakpoint-lg) {
                justify-content: left;
            }
            .email {
                @include font-normal;
                font-size: 1.3rem;
                color: $white;
                text-decoration: none;
                @include link-animation;

                &:hover {
                    color: $darkone;
                }
            }

            .social-icon {
                svg {
                    height: 18px;
                    fill: $white;
                    margin-left: 20px;
                    @include link-animation;
                }
                &:hover {
                    svg {
                        fill: $darkone;
                    }
                }
            }
        }
        .images {
            position:relative;
            margin-top:4rem;

            $imagewidth: 400px;
            $leftimageheight: 350px;
            $rightimageheight:450px;

            .image-one {
                position:absolute;
                right:0;
                width:$imagewidth;
                height:$leftimageheight;
                img {
                    width:100%;
                    height:100%;
                    object-fit:cover;
                }
            }
            .image-two {
                position:absolute;
                right:0;
                top:$leftimageheight + 30;
                width: $imagewidth;
                height: $leftimageheight;
                img {
                    width:100%;
                    height:100%;
                    object-fit:cover;
                }
            }
            .image-three {
                position: absolute;
                right:$imagewidth + 30;
                top: 0;
                width: $imagewidth;
                height: $rightimageheight;
                img {
                    width:100%;
                    height:100%;
                    object-fit:cover;
                }
            }

            &:before {
                content:"";
                position:absolute;
                right:-4rem;
                top:50px;
                background:$primarytwo;
                width:calc(100% + 220px);
                height:600px;
            }
        }
        
    }
    
    
}

.email-button {
    position:absolute;
    bottom:4rem;
    left:4rem;
    background:$primaryone;
    width:60px;
    height:60px;
    border-radius:50%;
    padding:15px;
    @include link-animation;
    @media screen and (max-width: $breakpoint-lg) {
        bottom: auto;
        left: auto;
        top: 3rem;
        right: 3rem;
    }
    @media screen and (max-width: $breakpoint-sm) {
        top: 2.2rem;
        right: 3rem;
    }
    svg {
        fill:$white;
    }
    &:hover {
        width:70px;
        height:70px;
    }
}

.popup-content {
    animation: anvil 0.3s cubic-bezier(0.8, 0.1, 0.1, 0.8) forwards;
    margin: auto;  
    background: $white;
    width:90%;
    max-width: 1100px;
    border-radius:4px;
    display:flex;
    overflow:hidden;
    .form-side {
        padding:50px;
        width:50%;
        background:$primaryone;
        display:flex;
        img {
            width:100%;
            height:auto;
        }
    }
    .form {
        padding: 40px;
        width:50%;
        .form-input {
            margin-bottom:20px;
            label {
                @include font-semi;
                display:block;
                margin-bottom:10px;
                font-size:1.1rem;
                color:$darkone;
            }
            input {
                padding:15px 15px;
                border:1px solid $primarytwo;
                width:100%;
                border-radius:4px;
                @include font-normal;
                @include link-animation;
                display:block;
                font-size:1.1rem;
                outline:none;
                &::placeholder {
                    color:$primarytwo;
                }
                &:focus {
                    border-color:$primarytwo;
                }
            }
            textarea {
                padding:15px 15px;
                border:1px solid $primarytwo;
                width:100%;
                border-radius:4px;
                @include font-normal;
                @include link-animation;
                display:block;
                font-size:1.1rem;
                outline:none;
                resize:none;
                &::placeholder {
                    color:$primarytwo;
                }
                &:focus {
                    border-color:$primarytwo;
                }
            }
            .errorMessage {
                color:$primaryone;
                font-size:0.8rem;
                margin-top:5px;
                @include link-animation;
            }
            &.error {
                input, textarea {
                    border-color:$primaryone;
                    border-left:6px solid $primaryone;
                }
            }
        }
        button {
            @include button;
            &:hover {
                svg {
                    animation: tilt-shaking 0.3s infinite;
                    &.spinner {
                        animation: spin 4s infinite;
                    }
                }
            }

        }
        .successMessage {
            color:$green;
            @include font-semi;
            margin-top:20px;
            svg {
                display:inline-block;
                width:16px;
                fill:$green;
                margin-left:7px;
                transform:translateY(2px);
            }
        }
        .hidden-input {
            visibility: hidden;
            position: absolute;
        }
    }
}

.popup-arrow {  
    color: $primaryone;
}

.popup-overlay {  
    animation: overlay 0.3s cubic-bezier(0.8, 0.1, 0.1, 0.8) forwards;
    background: rgba($darkone, 0.9);
}